import type { NextPage } from 'next'


const HomePage: NextPage = () => {
  return (
    <div>Home page</div>
  )
}

export default HomePage
